<template>
  <div class="achievement-container">
    <div class="item" v-for="item in itemList" :key="item.articleId">
      <div class="title" @click="goDetails(item.articleInfo)">
        <span class="column" v-if="item.articleInfo"
          >[{{ item.articleInfo.typeName }}]</span
        >
        <span class="name" v-if="item.circleFile">{{
          item.circleFile.resultName
        }}</span>
      </div>
      <div class="info">
        <!-- <span class="auther" v-if="item.articleInfo"></span> -->
        <span class="auther" v-if="item.articleInfo && item.articleInfo.authorList && item.articleInfo.authorList.split(',').length > 3">
          <i style="font-style: normal;" v-for="(e,index) in item.articleInfo.authorList.split(',')" :key="index">{{formatting(e,index)}}</i>
        </span>
        <span class="auther" v-if="item.articleInfo && item.articleInfo.authorList && item.articleInfo.authorList.split(',').length <= 3">{{ item.articleInfo.authorList }}</span>
        <span v-if="item.articleInfo">{{ item.articleInfo.mettingName }}</span>
        <span v-if="item.articleInfo">{{ item.articleInfo.mettingTime }}</span>
      </div>
      <IconButton2
        v-if="item.articleInfo"
        :every="item.articleInfo"
        :curcer="item.articleInfo.articleId"
      ></IconButton2>
    </div>
  </div>
</template>

<script>
import IconButton2 from "@/components/pager/IconButton2.vue";
export default {
  props: ["itemList"],
  components: {
    IconButton2,
  },
  methods: {
    formatting(item, index) {
      return index < 2 ?  `${item},` : index === 2 ? `${item} 等` : ''
    },
    goDetails(item) {
      if(!item){
        return;
      }
      console.log(item);
      let url;
      if (item.articleType == 0) {
        if (item.type == 0) {
          url = this.$router.resolve({
            path: "/paperDetail",
            query: {
              articleType: item.articleType,
              articleId: item.articleId,
            },
          });
        } else if (item.type == 1) {
          url = this.$router.resolve({
            path: "/conferencepapers",
            query: {
              articleType: item.articleType,
              articleId: item.articleId,
            },
          });
        } else if (item.type == 2) {
          url = this.$router.resolve({
            path: "/conferenceppt",
            query: {
              articleType: item.articleType,
              articleId: item.articleId,
            },
          });
        } else if (item.type == 4) {
          url = this.$router.resolve({
            path: "/conferenceother",
            query: {
              articleType: item.articleType,
              articleId: item.articleId,
            },
          });
        }
      } else if (item.articleType == 1) {
        url = this.$router.resolve({
          path: "/patentdetail",
          query: {
            articleType: item.articleType,
            articleId: item.articleId,
          },
        });
      } else if (item.articleType == 2) {
        url = this.$router.resolve({
          path: "/monographdetail",
          query: {
            articleType: item.articleType,
            articleId: item.articleId,
          },
        });
      } else if (item.articleType == 3) {
        url = this.$router.resolve({
          path: "/reportdetail",
          query: {
            articleType: item.articleType,
            articleId: item.articleId,
          },
        });
      } else if (item.articleType == 4) {
        url = this.$router.resolve({
          path: "/standarddetail",
          query: {
            articleType: item.articleType,
            articleId: item.articleId,
          },
        });
      } else if (item.articleType == 5 && item.type == 0) {
        url = this.$router.resolve({
          path: "/informationdetail",
          query: {
            articleType: item.articleType,
            articleId: item.articleId,
          },
        });
      }
      window.open(url.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  margin-top: 22px;
  border-bottom: 1px dashed #d6d6d6;
  padding-bottom: 18px;
  .title {
    cursor: pointer;
    font-size: 20px;
    .column {
      color: #b6b6b6;
    }
  }
  .name {
    margin-left: 5px;
  }
  .info {
    margin: 10px 0;
    font-size: 14px;
    color: #999999;
    display: flex;
    align-items: center;
    span {
      margin-right: 15px;
    }
    .auther {
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
    }
  }
}
</style>
