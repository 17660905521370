<template>
  <div class="reply-detail-achievements-container">
    <AchievementItem :itemList="itemList"></AchievementItem>
    <Paging
      :total="total"
      :curPage="pageNo"
      :limit="10"
      @changePager="handlePager"
    ></Paging>
  </div>
</template>

<script>
import AchievementItem from "../components/AchievementItem.vue";
import Paging from "@/components/paging/index.vue";
export default {
  components: {
    AchievementItem,
    Paging,
  },
  data() {
    return {
      pageNo: 1,
      total: 0,
      itemList: [],
    };
  },
  created() {
    this.achievementListByCircleIdOrDiscussId({
      type: 2,
      discussId: this.$route.query.id,
      pageNo: 1,
      pageSize: 5,
    });
  },
  methods: {
    handlePager(page) {
      this.achievementListByCircleIdOrDiscussId({
        type: 2,
        discussId: this.$route.query.id,
        pageNo: page,
        pageSize: 5,
      });
    },
    async achievementListByCircleIdOrDiscussId(data) {
      const resp =
        await this.$apis.circleServe.achievementListByCircleIdOrDiscussId(data);
      if (resp.data) {
        this.itemList = resp.data.list;
        this.pageNo = +resp.data.pageNum;
        this.total = +resp.data.total;
      }
    },
  },
};
</script>

<style></style>
